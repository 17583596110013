<template>
  <MainLayout>
    <div class="row cl-090D2E">
      <div class="mb-2 mb-md-3 col-12 ras-typo-big-title">
        Review your application, please.
      </div>
    </div>
    <template v-if="form">
      <div
        id="rsPersonal" 
        class="row my-5 flex-wrap"
      >
        <div class="col-12 mb-5 d-flex align-items-center">
          <div class="ras-typo-title col-10 col-md-5 mb-0">
            Your residential address
          </div>
          <div class="col-2 col-md-7 d-flex justify-content-end justify-content-lg-start">
            <router-link
              :to="{
                name: 'AboutYou',
                params: { id: $route.params.id },
                hash: '#addr',
              }"
              class="cl-0D41FF"
            >
              Edit
            </router-link>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Name
          </div>
          <div class="col-12 col-md-7">
            {{ form.aboutYou.first }} {{ form.aboutYou.last }}
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Residential address
          </div>
          <div class="col-12 col-md-7">
            {{ form.aboutYou.residenceAddr.addr }}
            <br>
            {{ form.aboutYou.residenceAddr.country }},
            {{ form.aboutYou.residenceAddr.state }},
            <br>
            {{ form.aboutYou.residenceAddr.postalCode }}
            {{ form.aboutYou.residenceAddr.city }}
          </div>
        </div>

        <div
          class="col-12 mb-3 d-flex flex-wrap"
          v-if="!form.aboutYou.differentMailingAddr"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Mailing Address
          </div>
          <div class="col-12 col-md-7">
            Same as Residential address
          </div>
        </div>

        <div
          class="col-12 mb-3 d-flex flex-wrap"
          v-if="form.aboutYou.differentMailingAddr"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Mailing Address
          </div>
          <div class="col-12 col-md-7">
            {{ form.aboutYou.mailing_addr.addr }}
            <br>
            {{ form.aboutYou.mailing_addr.country }},
            {{ form.aboutYou.mailing_addr.state }},
            <br>
            {{ form.aboutYou.mailing_addr.postal_code }}
            {{ form.aboutYou.mailing_addr.city }}
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            E-mail Address
          </div>
          <div class="col-12 col-md-7">
            {{
              form.prefilledData.email || genEmail
            }}
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Phone (Mobile)
          </div>
          <div class="col-12 col-md-7">
            {{ form.aboutYou.residenceAddr.phone }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Marital Status
          </div>
          <div class="col-12 col-md-7">
            {{ capitalize(form.lifeSituation.maritalStatus) }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Number Of Dependents
          </div>
          <div class="col-12 col-md-7">
            {{ form.lifeSituation.dependents }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Gender
          </div>
          <div class="col-12 col-md-7">
            {{ form.aboutYou.gender == "M" ? "Male" : "Female" }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Date Of Birth
          </div>
          <div class="col-12 col-md-7">
            {{ form.aboutYou.dob }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Country Of Birth
          </div>
          <div
            class="col-12 col-md-7"
            v-if="form.aboutYou.placeOfBirth"
          >
            {{ form.aboutYou.placeOfBirth }}
          </div>
          <div
            class="col-12 col-md-7"
            v-else-if="form.aboutYou.differentPlaceOfBirth"
          >
            {{ form.aboutYou.differentPlaceOfBirthDetails.placeOfBirth }}
          </div>
          <div
            class="col-12 col-md-7"
            v-else
          >
            {{ form.aboutYou.residenceAddr.country }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Primary Citizenship
          </div>
          <div
            class="col-12 col-md-7"
            v-if="form.aboutYou.citizenship"
          >
            {{ form.aboutYou.citizenship }}
          </div>
          <div
            class="col-12 col-md-7"
            v-else-if="form.aboutYou.differentCitizenship"
          >
            {{ form.aboutYou.differentCitizenshipDetails.citizenship }}
          </div>
          <div
            class="col-12 col-md-7"
            v-else-if="form.aboutYou.usCitizen"
          >
            United States
          </div>
          <div
            class="col-12 col-md-7"
            v-else
          >
            {{ form.aboutYou.residenceAddr.country }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            ID Document
          </div>
          <div class="col-12 col-md-7">
            {{ form.onlineIdentification.documentCountry }} {{ form.onlineIdentification.documentType == 'PASSPORT' ? 'Passport' : 'National ID' }}
            <br>
            {{ form.aboutYou.passport_number }} valid till {{ form.onlineIdentification.IdExpirationDate }}
            <span
              class="small cl-2D254D50"
            ></span>
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Employment
          </div>
          <div class="col-12 col-md-7">
            {{ form.lifeSituation.employment }}
          </div>
        </div>
        <template
          v-if="
            ['Employed', 'Self-employed'].includes(
              form.lifeSituation.employment
            )
          "
        >
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Employer name
            </div>
            <div class="col-12 col-md-7">
              {{ form.lifeSituation.employmentDetails.employer }}
            </div>
          </div>
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Nature of Employer's Business
            </div>
            <div
              class="col-12 col-md-7"
            >
              {{ form.lifeSituation.employmentDetails.employer_business }}
            </div>
          </div>
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Occupation
            </div>
            <div class="col-12 col-md-7">
              {{ form.lifeSituation.employmentDetails.occupation }}
            </div>
          </div>
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Employer Address
            </div>
            <div class="col-12 col-md-7">
              {{
                form.lifeSituation.employmentDetails.employer_address.addr
              }}
              <br>
              {{
                form.lifeSituation.employmentDetails.employer_address.country
              }},
              {{
                form.lifeSituation.employmentDetails.employer_address.state
              }},
              <br>
              {{
                form.lifeSituation.employmentDetails.employer_address
                  .postal_code
              }}
              {{ form.lifeSituation.employmentDetails.employer_address.city }}
            </div>
          </div>
        </template>
      </div>

      <div 
        id="rsFinancial" 
        class="row my-5 flex-wrap"
      >
        <div class="col-12 mb-5 d-flex align-items-center">
          <div class="ras-typo-title col-10 col-md-5 mb-0">
            Financial Information
          </div>
          <div class="col-2 col-md-7 d-flex justify-content-end justify-content-lg-start">
            <router-link
              :to="{
                name: 'InvestmentProfile',
                params: { id: $route.params.id },
                hash: '#addr',
              }"
              class="cl-0D41FF"
            >
              Edit
            </router-link>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Annual Net Income in CHF
          </div>
          <div class="col-12 col-md-7">
            {{ form.investmentProfile.annualNetIncome }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Net Worth in CHF
          </div>
          <div class="col-12 col-md-7">
            {{ form.investmentProfile.illiquidNetWorth }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Liquid Net Worth in CHF
          </div>
          <div class="col-12 col-md-7">
            {{ form.investmentProfile.liquidNetWorth }}
          </div>
        </div>
        <div
          class="col-12 mb-3 d-flex flex-wrap"
          v-if="form.lifeSituation.additionalIncome"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Sources of Wealth
          </div>
          <div class="col-12 col-md-7">
            Allowance / Spousal Income -
            {{ form.lifeSituation.additionalIncomeDetails.allowance }}%
            <br>
            Income from Employment -
            {{ form.lifeSituation.additionalIncomeDetails.employment }}%
            <br>
            Inheritance/Gift -
            {{ form.lifeSituation.additionalIncomeDetails.inheritance }}%
            <br>
            Property -
            {{ form.lifeSituation.additionalIncomeDetails.property }}%
            <br>
            Market Trading Profits -
            {{ form.lifeSituation.additionalIncomeDetails.market }}%
            <br>
            Other - {{ form.lifeSituation.additionalIncomeDetails.other }}%
            <span
              v-if="form.lifeSituation.additionalIncomeDetails.other > 0"
            >
              (Notes:
              {{ form.lifeSituation.additionalIncomeDetails.reason }})
            </span>
            <br>
            Interest / Dividend Income -
            {{ form.lifeSituation.additionalIncomeDetails.interest }}%
            <br>
          </div>
        </div>

        <template v-if="form.lifeSituation.neverInvestBefore == false">
          <div class="col-12 col-md-5 ras-typo-field">
            Investment Experience
          </div>
          <div class="col-12 col-md-7" />
          <div
            class="col-12 mb-3 d-flex flex-wrap"
            v-for="item in form.lifeSituation.investmentExperienceDetails"
            :key="'exp-' + item.asset_class"
          >
            <div class="col-12 col-md-5 ras-typo-field">
              {{ item.asset_class }}
            </div>
            <div class="col-12 col-md-7">
              {{ item.years_trading }}; {{ item.trades_per_year }};
              {{ item.knowledge_level }}
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-12 mb-3 d-flex flex-wrap">
            <div class="col-12 col-md-5 ras-typo-field">
              Investment Experience
            </div>
            <div class="col-12 col-md-7">
              I have never invest before
            </div>
          </div>
        </template>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Investment Objectives
          </div>
          <div class="col-12 col-md-7">
            {{ investmentObjectives }}
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Account Type
          </div>
          <div class="col-12 col-md-7">
            Cash
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Trading Permissions
          </div>
          <div class="col-12 col-md-7">
            EURONEXT-BOND, FRENCH-STK, IBIS, SEHK-STK, SEHK-BOND, ITALY-STK,
            JAPAN-STK, MEXI-STK, NORWAY-STK, BM-STK, SFB-STK, VIRTXCHF,
            DUTCH-STK, LSE, NASDTAS, US-Sec, US-SecAlgo, US-BOND, US-MUNIES,
            EBS-IOPT, SGX-STK
          </div>
        </div>
        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Management Fees
          </div>
          <div
            v-if="form.prefilledData.promocode"
            class="col-12 col-md-7"
          >
            0.4% annually
          </div>
          <div
            v-else
            class="col-12 col-md-7"
          >
            0.5% annually
          </div>
        </div>
      </div>

      <div 
        id="rsTax"
        class="row my-5 flex-wrap"
      >
        <div class="col-12 mb-5 d-flex align-items-center">
          <div class="ras-typo-title col-10 col-md-5 mb-0">
            Tax Information
          </div>
          <div class="col-2 col-md-7 d-flex justify-content-end justify-content-lg-start">
            <router-link
              :to="{
                name: 'AboutYou',
                params: { id: $route.params.id },
                hash: '#ayTIN',
              }"
              class="cl-0D41FF"
            >
              Edit
            </router-link>
          </div>
        </div>

        <div class="col-12 mb-3 d-flex flex-wrap">
          <div class="col-12 col-md-5 ras-typo-field">
            Tax residency
          </div>
          <div
            v-if="form.aboutYou.usCitizen"
            class="col-12 col-md-7"
          >
            United States, SSN:
            <span>{{ form.aboutYou.tax_number }}</span>
          </div>
          <div
            v-else
            class="col-12 col-md-7"
          >
            {{ form.aboutYou.residenceAddr.country }}, Tax Number:
            <span
              v-if="form.aboutYou.dontHaveTax"
            >None - {{ form.aboutYou.dontHaveTaxDetails.option }}</span>
            <span v-else>{{ form.aboutYou.tax_number }}</span>
            <br>
            <span v-if="form.aboutYou.differentTaxResidency">
              {{ form.aboutYou.differentTaxResidencyDetails.country }}, Tax
              Number: {{
                form.aboutYou.differentTaxResidencyDetails.tax_number
              }}
              <br>
            </span>
          </div>
        </div>
        <div
          v-if="!form.aboutYou.usCitizen"
          class="col-12 mb-3 d-flex flex-wrap"
        >
          <div class="col-12 col-md-5 ras-typo-field">
            Treaty Benefits Qualifications
          </div>
          <div class="col-12 col-md-7">
            <span v-if="form.aboutYou.differentTaxResidency">
              {{
                form.aboutYou.differentTaxResidencyDetails.country
              }}
            </span>
            <span v-else>{{ form.aboutYou.residenceAddr.country }}</span>
          </div>
        </div>
        <div
          v-if="form.aboutYou.usCitizen"
          class="col-12 mb-3 d-flex flex-wrap flex-wrap"
        >
          <div class="col-12 mb-5 d-flex align-items-center" />
          <div class="col-12 mb-5 d-flex align-items-center">
            <div class="ras-typo-title col-12 mb-0">
              Internal Revenue Service Certification
            </div>
          </div>
          <div class="col-12 mt-2">
            Name: {{ form.aboutYou.first }} {{ form.aboutYou.last }}
            <br><br>
            Social Security Number: {{ form.aboutYou.tax_number }}
            <br><br>
            Under penalties of perjury, I certify that:
          </div>

          <div class="col-12 mt-3">
            The number shown on this form is my correct taxpayer identification number (or I am waiting for a number to be issued to me), and
          </div>
          <div class="col-12 mt-3 d-flex align-items-center">
            <div class="swi check-square swi_mini d-inline-block me-2" />
            <div class="me-4">
              Yes
            </div>
            <div>No</div>
          </div>

          <div class="col-12 mt-3">
            I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding, and
          </div>
          <div class="col-12 mt-3 d-flex align-items-center">
            <div class="swi check-square swi_mini d-inline-block me-2" />
            <div class="me-4">
              Yes
            </div>
            <div>No</div>
          </div>

          <div class="col-12 mt-3">
            I am a U.S. citizen or other U.S. person.
          </div>
          <div class="col-12 mt-3 d-flex align-items-center">
            <div class="swi check-square swi_mini d-inline-block me-2" />
            <div class="me-4">
              Yes
            </div>
            <div>No</div>
          </div>

          <div class="col-12 mt-3">
            Contact customer service if you cannot make either representation. <br>

            <strong>
              The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.
            </strong>
          </div>
          <div class="col-12 mt-2">
            By clicking on "continue," you (i) certify, under penalty of perjury that the foregoing statements on the IRS certification are true and correct; (ii) agree it represents your digital signature, which is the equivalent of your written signature and (iii) consent to the use of electronic records to evidence your certification/agreement.
          </div>
        </div>
        <div
          v-else
          class="col-12 mb-3 d-flex flex-wrap flex-wrap"
          id="rsCertification"
        >
          <div class="col-12 mb-5 d-flex align-items-center" />
          <div class="col-12 mb-5 d-flex align-items-center">
            <div class="ras-typo-title col-12 mb-0">
              Certification
            </div>
          </div>
          <div class="col-12 mt-2">
            Under penalties of perjury, I declare that I have examined the
            information on this form and to the best of my knowledge and belief
            it is true, correct, and complete. I further certify under penalties
            of perjury that:
            <ul>
              <li>
                I am the individual that is the beneficial owner (or am
                authorized to sign for the individual that is the beneficial
                owner) of all the income to which this form relates or am using
                this form to document myself as an individual that is an owner
                or account holder of a foreign financial institution,
              </li>
              <li>
                The person named on line 1 of this form is not a U.S. person,
                The income to which this form relates is:
                <ul>
                  <li>
                    not effectively connected with the conduct of a trade or
                    business in the United States,
                  </li>
                  <li>
                    effectively connected but is not subject to tax under an
                    income tax treaty, or
                  </li>
                  <li>
                    the partner's share of a partnership's effectively connected
                    income,
                  </li>
                </ul>
              </li>
              <li>
                The person named on line 1 of this form is a resident of the
                treaty country listed on line 9 of the form (if any) within the
                meaning of the income tax treaty between the United States and
                that country, and
              </li>
              <li>
                For broker transactions or barter exchanges, the beneficial
                owner is an exempt foreign person as defined in the instructions
                to IRS Form W-8BEN.
              </li>
              <li>
                <strong>
                  I agree that I will submit a new form within 30 days if any
                  certification made on this form becomes incorrect.
                </strong>
              </li>
            </ul>
          </div>

          <div class="col-12 mt-3">
            I agree under penalty of perjury that each certification above is
            true and correct and confirm I have reviewed the information on this
            form W-8BEN (including any prepopulation) and it is true and
            correct.
          </div>
          <div class="col-12 mt-3 d-flex align-items-center">
            <div class="swi check-square swi_mini d-inline-block me-2" />
            <div class="me-4">
              Yes
            </div>
            <div>No</div>
          </div>

          <div class="col-12 mt-3">
            I consent to the collection and distribution of tax forms in an
            electronic format in lieu of paper, including through the account
            management portal.
          </div>
          <div class="col-12 mt-3 d-flex align-items-center">
            <div class="swi check-square swi_mini d-inline-block me-2" />
            <div class="me-4">
              Yes
            </div>
            <div>No</div>
          </div>

          <div class="col-12 mt-3">
            Contact customer service if you cannot make either representation.
            <br>Furthermore, I authorize this form to be provided to any withholding
            agent that has control, receipt, or custody of the income of which I
            am the beneficial owner or any withholding agent that can disburse
            or make payments of the income of which I am the beneficial
            owner.
            <br>
            <strong>
              The US Internal Revenue Service does not require your consent to
              any provisions of this document other than the certifications
              required to establish your status as a non-U.S. person and, if
              applicable, obtain a reduced rate of withholding.
            </strong>
          </div>
        </div>
      </div>

      <div 
        id="rsSignature"
        class="row my-5 flex-wrap"
      >
        <div class="col-12 mb-5 d-flex align-items-center">
          <div class="ras-typo-title col-12 mb-0">
            Signature
          </div>
        </div>

        <div class="col-12">
          <div
            class="sw-check col-12 mt-3 p-md-0"
            :class="{ 'form-error': !signDataValid.check && !signData.check }"
          >
            <div
              class="sw-label"
              :class="{ 'form-error': !signDataValid.check && !signData.check }"
              v-if="!signDataValid.check && !signData.check"
            >
              <div class="sw-label-text">You have to check here to continue</div>
            </div>
            <input
              id="IAgree"
              type="checkbox"
              v-model="signData.check"
            >
            <label for="IAgree">
              I’ve carefully read the
              <a
                href="javascript:void(0)"
                @click="showPopup = true"
              >Agreements and Disclosures</a>,
              <a href="https://www.simplewealth.ch/terms/" target="_blank">Terms & Conditions</a>
              and
              <a href="https://www.simplewealth.ch/policy/" target="_blank">Privacy Policy</a>
            </label>
          </div>
        </div>

        <div class="col-12 mt-3">
          <div class="mb-3">
            By typing my signature and clicking 'Continue' below, I confirm
            that:
          </div>
          <ol>
            <li>
              All information and/or documentation provided by me during the
              account application process is accurate, complete and up-to-date
              and that I intend Interactive Brokers to rely upon it;
            </li>
            <li>
              I have read and understood all of the information provided to me
              by Simplewealth;
            </li>
            <li>
              I consent and agree to the terms of all the above agreements and
              disclosures provided to me during the account application process:
              and
            </li>
            <li>
              I understand and agree that my electronic signature is the legal
              equivalent of a manual written signature.
            </li>
          </ol>
        </div>

        <div class="col-12 mt-3">
          <div class="col-12 mb-3 d-flex">
            <div class="col-6 col-md-8">
              Account Title
            </div>
            <div class="col-6 col-md-4">
              Dated
            </div>
          </div>
          <div class="col-12 mb-3 d-flex">
            <div
              class="col-6 col-md-8 ras-typo-field"
            >
              {{ form.aboutYou.first }}&nbsp;{{ form.aboutYou.last }}
            </div>
            <div class="col-6 col-md-4 ras-typo-field">
              {{ date }}
            </div>
          </div>

         
        <div class="col-12 col-md-auto mt-3">
          <div
            id="rsContinue"
            class="btn btn-primary col-6 mt-3 px-4 button-padding"
            @click="showPopupContinue = true"
          >
            Continue &nbsp;&nbsp;&rarr;
          </div>
        </div>
        </div>
      </div>
    </template>

    <div
      class="popupContainer"
      v-if="showPopup"
    >
      <div class="popupBlock">
        <div
          class="swi close closeBtn curp"
          @click="showPopup = false"
        />
        <div class="popupTitle">
          Agreements and Disclosures
        </div>
        <ul>
          <li
            v-for="item in formListId"
            :key="'form-id-' + item"
            class="mt-2"
          >
            <a
              :href="'https://onboarding-api.simplewealth.ch/dev/forms/Form' + item + '.pdf'"
              target="_blank"
            >{{ formNames[item] ? formNames[item].trim() : ('Form' + item + '.pdf') }}</a>
          </li>
        </ul>
        <div
          id="rsPopUpClose"
          class="btn btn-primary mt-3 px-4 button-padding"
          @click="showPopup = false"
        >
          OK
        </div>
      </div>
    </div>
    <div
      id="rsSignPopUp"
      class="popupContainer"
      v-if="showPopupContinue"
    >
      <div class="popupBlock popupBlock-agreement">
        <div
          class="swi close closeBtn curp"
          @click="showPopupContinue = false"
        />
        <div class="popupTitle">
          Sign electronically
        </div>

         <div class="col-12 mt-2 d-flex flex-wrap">
            <small class="col-12">
              Sign this electronically by typing your name exactly as shown
              below.
            </small>
            <small class="col-12">
              Accepted signature(s): {{ form.aboutYou.first }}&nbsp;{{
                form.aboutYou.last
              }}
            </small>
          </div>

          <div
            id="rsName"
            class="col-12 mt-3 sw-label"
            :class="{ 'form-error': !signDataValid.sign && !signValid }"
          >
            <div class="d-flex swl-content">
              <div class="sw-textfield w-100">
                <input
                  type="text"
                  placeholder="Required"
                  v-model="signData.sign"
                >
              </div>
            </div>
          </div>
       

          <div
            id="rsSign"
            class="btn btn-primary col-6 mt-3 px-4 button-padding"
            @click="sign"
          >
            Sign &nbsp;&nbsp;&rarr;
          </div>
      </div>
    </div>
  </MainLayout>
</template>

<style scoped lang="scss">
.popupContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgb(255 255 255 / 80%);
  display: flex;
  align-items: center;
  justify-content: center;
 

  .popupBlock {
    background: #fff;
    padding: 50px;
    box-shadow: 0px 2px 5px 0px #dddddd;
    border-radius: 10px;
    min-width: 80vw;
    position: relative;

    .popupTitle {
      font-family: "SuisseIntl-Semibold";
      font-size: 24px;
      line-height: 125%;
      margin-bottom: 10px;
    }

    ul {
      max-height: 65vh;
      overflow-y: scroll;
    }

    .closeBtn {
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }
   .popupBlock-agreement{
    min-width: 50vw;
  }
    @media (max-width: 468px) {
      .popupBlock-agreement{
      min-width: 100vw;
      }

    }
}
</style>

<script>
import MainLayout from "@/layouts/MainLayout.vue";
import { mapActions, mapGetters } from "vuex";
import api from "@/services/api";

export default {
  components: {
    MainLayout,
  },
  data: () => {
    return {
      form: null,
      date: new Date().toGMTString(),
      timer: null,
      signData: {
        sign: null,
        check: false,
      },
      signDataValid: {
        sign: true,
        check: true,
      },
      formNames: {},
      showPopup: false,
      showPopupContinue: false,
    };
  },
  computed: {
    ...mapGetters(["onbForm"]),
    investmentObjectives() {
      let objectives = '';
      ['Growth', 'Hedging', 'Preservation/Income', 'Speculation'].forEach((value) => {
        // lifeSituation keys are in lowerkeys
        if (![null, undefined, "", false].includes(this.form.lifeSituation[value.toLowerCase()])) {
          objectives = objectives == '' ? value : `${objectives}, ${value}`;
        }
      })
      return objectives;
    },
    signValid() {
      return (
        this.signData.sign !== "" &&
        this.signData.sign ===
        this.form.aboutYou.first + " " + this.form.aboutYou.last
      );
    },
    genEmail() {
      if (!this.form) return '';
      return `${this.form.aboutYou.first}.${this.form.aboutYou.last}`.replace(/[\W_-]+/g,".")+`@clients.simplewealth.ch`
    },
    formListId() {
      if (!this.form) return [];
      if (
        [
          'Andorra',
          'Channel Islands and Jersey',
          'Gibraltar',
          'Guernsey',
          'Ireland',
          'Isle of Man',
          'Liechtenstein',
          'Monaco',
          'Norway',
          'San Marino',
          'Switzerland',
          'United Kingdom'
        ].includes(
          this.form.aboutYou.residenceAddr.country
        )
      ) {
        return [
          4041, 3230, 3044, 3085, 4070, 9490, 4154, 3077, 4022, 3043,
          6112, 3207, 4420, 5009, 4421, 3058, 4016, 6108, 3070, 3089, 3071,
          3024, 4024, 5013, 2109, 3094, 3081, 3074, 2192, 9130, 5001
        ];
      } else {
        return [
          4041, 3230, 3044, 3085, 4070, 9490, 4154, 3077, 4022, 3043,
          4772, 6108, 3089, 2109, 4538, 4762, 4757, 4758, 4756, 4753, 4759,
          4754, 4451, 4781, 4760, 4783, 4750, 4769, 4767, 4763, 5001
        ];
      }
    },
  },
  methods: {
    ...mapActions(["loginTsSend", "signTsSend"]),
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getNow() {
      this.date = new Date().toGMTString();
    },
    sign() {
      let valid = true;

      if (!this.signData.check) {
        this.signDataValid.check = false;
        valid = false;
      }
      if (!this.signValid) {
        this.signDataValid.sign = false;
        valid = false;
      }

      if (!valid) {
        if (!this.signData.check) this.showPopupContinue=false;
        setTimeout(() => {
          document.getElementsByClassName("form-error")[0].scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      } else {
        this.signTsSend();

        this.$store.state.isDataCheck = true;
        this.$store.state.blur = true;

        this.startTimer();

        let vm = this;
        setTimeout(() => {
          vm.checkData();
        }, 5000)

      }
    },
    startTimer() {
      let timeMax = 39;
      let time = timeMax;
      let vm = this;
      this.$store.state.reviewTimer = setInterval(() => {
        time = time - 0.05;
        if (time <= 0) {
          clearInterval(vm.$store.state.reviewTimer);
          vm.$store.state.reviewTimer = null;
          vm.$store.state.isDataCheck = false;
          vm.$store.state.blur = false;
          vm.$store.state.timerPercent = 0;
          vm.$router.push({
            name: "ThankYou",
            params: {
              id: vm.$route.params.id,
            },
          });
        } else {
          vm.$store.state.timerPercent = 100 - (time * 100) / timeMax;
        }
      }, 50);
    },
    async checkData() {
      const res = await api.get("/onboarding/" + this.$route.params.id);

      if (res.data.data.ibxml && res.data.ibxml.success == true) {
        this.$store.state.isDataCheck = false;
        this.$store.state.blur = false;
        this.$router.push({
          name: "ThankYou",
          params: {
            id: this.$route.params.id,
          },
        });
      } else if (res.data.data.ibxml && res.data.data.ibxml.success == false) {
        this.$store.state.dataCheckErrors = res.data.data.ibxml.errors;
        clearInterval(this.$store.state.reviewTimer);
        this.$store.state.reviewTimer = null;
      } else {
        let vm = this;
        if (this.$store.state.isDataCheck) {
          setTimeout(() => {
            vm.checkData();
          }, 5000)
        }
      }
    },
    async fetchFormNames() {
      const res = await api.get("/forms.json");
      this.formNames = res.data;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
    this.$store.state.isDataCheck = false;
    this.$store.state.blur = false;
    this.$store.state.dataCheckErrors = null
  },
  async mounted() {
    await this.fetchFormNames();
    this.form = this.onbForm;

    let vm = this;
    this.timer = setInterval(() => {
      vm.getNow();
    }, 1000);
    this.loginTsSend();
  },
};
</script>